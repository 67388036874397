import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import BitmapEditor from '../BitmapEditor/BitmapEditor';
import './PhotoEditor.scss';
import EditorMenu, { MenuTypes } from './EditorMenu/EditorMenu';
import Modal from '../Modal/Modal';
import FrameUpgradeModal from './FrameUpgradeModal/frameUpgradeModal';
import { useTranslation } from 'react-i18next';
import pictureTemplateActions from '../../actions/pictureTemplateActions';
import productActions from '../../actions/productsActions';
import useFontLoader from './useFontLoader';
import { trackingClick } from '../../actions/trackingActions';
import classNames from 'classnames';
import { fontsList } from '../BitmapEditor/fonts';
import { Object } from 'fabric/fabric-impl';
import { ILimitedIText, LimitedIText } from '../BitmapEditor/objects/BitmapEditor.TextObject';
import { LimitedTextbox } from '../BitmapEditor/objects/BitmapEditor.TextAreaObject';
import { FontMenu } from './EditorMenu/FontMenu';
import { Template } from '../BitmapEditor/BitmapEditor.types';
import jsPDF from 'jspdf';
import photoEditorActions from '../../actions/photoEditorActions';

const project = process.env.REACT_APP_PROJECT_ID;

type FrameTypes = 'black' | 'natural' | 'white' | 'light grey';
type Templates = Record<string, Template>;
type SkuType = 'card' | 'wall' | 'canvas';

const PhotoEditor = () => {
  const [templates, setTemplates] = useState<Templates>();
  const [selectedTemplate, setSelectedTemplate] = useState<Template>();
  const [selectedFrame, setSelectedFrame] = useState<FrameTypes>('black');
  const [isLoading, setIsLoading] = useState(true);
  const [scaleFactor, setScaleFactor] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [validationModalText, setValidationModalText] = useState<string>('');
  const [saveModalShow, setSaveModalShow] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<MenuTypes>(MenuTypes.DEFAULT);
  const [selectedFont, setSelectedFont] = useState('');
  const [activeObject, setActiveObject] = useState<Object | null>(null);
  const [productKey, setProductKey] = useState('701');
  const [isFlipped, setIsFlipped] = useState(false);
  const [productType, setProductType] = useState<SkuType>();
  const [selectedCollage, setSelectedCollage] = useState('');

  const bitmapEditorRef = useRef<any>(null);
  const frontEditorRef = useRef<any>(null);
  const backEditorRef = useRef<any>(null);
  const selectedTextObject = useRef(null);

  const history = useHistory();
  const { t } = useTranslation();

  const frameColor = classNames(`${selectedFrame.replace(' ', '')}Frame`);
  const isFontLoaded = useFontLoader({ google: { families: fontsList.map((font) => font.name) } });
  const handleFlip = () => {
    setIsFlipped((prev) => !prev);
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sku = params.get('sku');
    if (!sku) {
      window.location.href = 'https://www.klikkie.com/products/walldecor';
    } else {
      setProductKey(sku);
    }
  }, [history]);

  useEffect(() => {
    const fetchTemplates = async () => {
      const listOfTemplates = await pictureTemplateActions.getPictureTemplates();
      const PRODUCT_TYPE = await getSkuType();

      setProductType(PRODUCT_TYPE);
      setTemplates(listOfTemplates);

      if (PRODUCT_TYPE === 'wall') {
        const template = 'Frame-Classic-2x2-2T';
        setSelectedCollage(template);
        setSelectedTemplate(listOfTemplates[template]);
      } else if(PRODUCT_TYPE === 'canvas') {
        const template = 'Frame-Canvas-1x1';
        setSelectedCollage(template);
        setSelectedTemplate(listOfTemplates[template]);
      } else {
        const template = 'Postcard-1x1-2TB';
        setSelectedCollage(template);
        setSelectedTemplate(listOfTemplates[template]);
      }
    };

    fetchTemplates();
  }, []);

  useEffect(() => {
    const fullFrame = document.querySelector('.fullFrame') as HTMLElement;
    if (fullFrame) {
      const newBorderWidth = 500 * scaleFactor;
      fullFrame.style.borderWidth = `${newBorderWidth}px`;
      fullFrame.style.borderImageWidth = `${newBorderWidth}px`;
    }
  }, [selectedTemplate, scaleFactor]);

  const handleTemplateChangeByName = (templateName: string) => {
    if (templates?.[templateName]) {
      setSelectedTemplate(templates[templateName]);
    }
  };

  const generateIncompleteMessage = (data: any, type: SkuType) => {
    let message = `<div style='text-align: left;'>${t('BitmapEditor.validationError.title')}<br>`;

    if (type === 'wall' || type === 'canvas') {
        const frontText = data.front?.text || 0;
        const frontImages = data.front?.image || 0;
        if (frontImages > 0) {
            message += `- ${frontImages} ${t('BitmapEditor.backModal.missedImages')}<br>`;
        }
        if (frontText > 0) {
          message += `- ${frontText} ${t('BitmapEditor.backModal.missedText')}<br>`;
        }
    } else {
        const frontText = data.front?.text || 0;
        const frontImages = data.front?.image || 0;
        const backText = data.back?.text || 0;
        const backImages = data.back?.image || 0;

        if (frontImages > 0) {
            message += `- ${frontImages} ${t('BitmapEditor.backModal.missedImagesOnFrontSide')}<br>`;
        }
        if (frontText > 0) {
            message += `- ${frontText} ${t('BitmapEditor.backModal.missedTextOnFrontSide')}<br>`;
        }
        if (backImages > 0) {
            message += `- ${backImages} ${t('BitmapEditor.backModal.missedTextOnBackSide')}<br>`;
        }
        if (backText > 0) {
            message += `- ${backText} ${t('BitmapEditor.backModal.missedTextOnBackSide')}<br>`;
        }
    }

    return message;
  }

  const handleValidation = () => {
    trackingClick('bitmapEditor', 'editor_ask_for_checkout');

    if ((productType === 'wall' || productType === 'canvas') && bitmapEditorRef.current) {
      const validateBitmapCanvas = bitmapEditorRef.current.validateCanvasObjects();
      const validationObject = {
        'front': validateBitmapCanvas,
      }

      if(validateBitmapCanvas.total > 0) {
        const modalText = generateIncompleteMessage(validationObject, 'card');
        setValidationModalText(modalText);
        setShowValidationModal(true);
      } else {
        handleSave();
      }
    } else if (productType === 'card' && frontEditorRef.current && backEditorRef.current) {
      const validateFrontCanvas = frontEditorRef.current.validateCanvasObjects();

      // return;
      const validateBackCanvas = backEditorRef.current.validateCanvasObjects();
      const validationObject = {
        'front': validateFrontCanvas,
        'back': validateBackCanvas
      }

      if(validateFrontCanvas.total + validateBackCanvas.total > 0) {
        const modalText = generateIncompleteMessage(validationObject, 'card');
        setValidationModalText(modalText);
        setShowValidationModal(true);
      } else {
        handleSave();
      }
    }
  }

  const handleSave = () => {
    trackingClick('bitmapEditor', 'editor_ask_for_checkout');
    const sku = new URLSearchParams(window.location.search).get('sku') || '701';
    sku === '703' || sku === '753' ? setSaveModalShow(true) : setShowUpgradeModal(true);
  };

  const handleBack = () => {
    trackingClick('bitmapEditor', 'editor_back_btn');
    setShowModal(true);
  };

  const confirmBack = () => {
    trackingClick('bitmapEditor', 'editor_accept_back_action');
    setShowModal(false);
    window.history.back();
  };

  const cancelBack = () => {
    trackingClick('bitmapEditor', 'editor_cancel_back_action');
    setShowModal(false);
  };

  const confirmCheckout = async () => {
    trackingClick('bitmapEditor', 'editor_accept_checkout_action');
    trackingClick('bitmapEditor', 'editor_save_image_btn');

    const productConfigurations = {
      '751': {
        pageFormat: [154, 111],
        imageWidth: 111,
        imageHeight: 154,
        xOffset: 154,
        yOffset: -43,
        rotation: 90,
      },
      '752': {
        pageFormat: [216, 154],
        imageWidth: 154,
        imageHeight: 216,
        xOffset: 216,
        yOffset: -62,
        rotation: 90,
      },
      '753': {
        pageFormat: [303, 216],
        imageWidth: 216,
        imageHeight: 303,
        xOffset: 303,
        yOffset: -87,
        rotation: 90,
      },
    };

    if ((productType === 'wall' || productType === 'canvas') && bitmapEditorRef.current) {
      const wallImageDataURL = bitmapEditorRef.current.saveCanvas(selectedFrame);

      const dataBlob = await fetch(wallImageDataURL).then((res) => res.blob());

      try {
        const response = await photoEditorActions.saveCanvas(selectedFrame, dataBlob);
        if (response) {
          const params = new URLSearchParams(window.location.search);
          const sku = params.get('sku') || '701';

          const envParam = project === 'klikkie-dev' ? '&env=dev' : '';

          window.location.href = `https://www.klikkie.com/membership/start?sku=${productKey}&units=1&photoId=${response.id}${envParam}`;
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    } else if (productType === 'card' && frontEditorRef.current && backEditorRef.current) {
      const frontDataURL = frontEditorRef.current.saveCanvas(1);
      const backDataURL = backEditorRef.current.saveCanvas(1);
    
      if (frontDataURL && backDataURL) {
        const config = productConfigurations[productKey as keyof typeof productConfigurations] || productConfigurations['751'];
    
        const pdf = new jsPDF({
          orientation: 'landscape',
          unit: 'mm',
          format: config.pageFormat,
        });
    
        pdf.addImage(frontDataURL, 'JPEG', config.xOffset, config.yOffset, config.imageWidth, config.imageHeight, undefined, undefined, config.rotation);
    
        pdf.addPage();
    
        const rotatedXOffset = 0;
        const rotatedYOffset = config.imageHeight * -1;

        pdf.addImage(
          backDataURL,
          'JPEG',
          rotatedXOffset,
          rotatedYOffset,
          config.imageWidth,
          config.imageHeight,
          undefined,
          undefined,
          270
        );
    
        const pdfBlob = pdf.output('blob');
    
        // const blobUrl = URL.createObjectURL(pdfBlob);
        // window.open(blobUrl);
    
        try {
          const response = await photoEditorActions.saveCanvas('postcard', pdfBlob);
          if (response) {
            const params = new URLSearchParams(window.location.search);
            const sku = params.get('sku') || '701';
    
            const envParam = project === 'klikkie-dev' ? '&env=dev' : '';
    
            window.location.href = `https://www.klikkie.com/membership/start?sku=${productKey}&units=1&photoId=${response.id}${envParam}`;
          }
        } catch (error) {
          console.error('Error uploading file:', error);
        }
      }
    }
  };

  const cancelCheckout = () => {
    trackingClick('bitmapEditor', 'editor_cancel_checkout_action');
    setSaveModalShow(false);
  };

  const getSkuType = async (): Promise<SkuType | undefined> => {
    const sku = new URLSearchParams(window.location.search).get('sku');
    if (sku) {
      const productInfo = await productActions.getProduct(sku);
      if(productInfo.type === 'wall' && productInfo.variant === 'Classic') {
        return 'wall';
      } else if(productInfo.type === 'wall' && productInfo.variant === 'Canvas') {
        return 'canvas';
      }
      return productInfo.type;
    }
  };

  if (!templates || !selectedTemplate || !isFontLoaded || !productType) return <>Loading</>;

  return (
    <main className="photoEditorWrapper">
      <div className="actionBar">
        <button className="backBtn pointer" onClick={handleBack}></button>
        <p className="actionBarTitle">
          {(productType === 'wall' || productType === 'canvas') && t('BitmapEditor.editor.frameTitle')}
          {productType === 'card' && t('BitmapEditor.editor.cardTitle')}
        </p>
        <button className="saveBtn pointer" onClick={handleValidation}>
          {t('BitmapEditor.saveModal.checkout')}
        </button>
      </div>

      {(productType === 'wall' || productType === 'canvas') ? (
        <div className="frameWrapper">
          <div className={classNames('canvas-wrapper', 'fullFrame', frameColor, { loaded: !isLoading })}>
            <BitmapEditor
              ref={bitmapEditorRef}
              setActiveObject={setActiveObject}
              setScale={setScaleFactor}
              setIsLoading={setIsLoading}
              template={selectedTemplate}
              productKey={productKey}
              sideContainer=".canvas-container"
            />
          </div>
        </div>
      ) : (
        <>
          <div className="sideSelector">
            <div className="sideSelectorContainer">
              <button className={`sideBtn ${!isFlipped ? 'active' : ''}`} onClick={handleFlip}>
                {t('BitmapEditor.postCard.front')}
              </button>
              <button className={`sideBtn ${isFlipped ? 'active' : ''}`} onClick={handleFlip}>
                {t('BitmapEditor.postCard.back')}
              </button>
            </div>
          </div>
          <div className={classNames('frameWrapper', { flipped: isFlipped })}>
            <div className={classNames('canvas-wrapper', { loaded: !isLoading })}>
              <div className="cardFace">
                <div className="cardFront" style={{ display: isFlipped ? 'none' : 'block' }}>
                  <BitmapEditor
                    ref={frontEditorRef}
                    setActiveObject={setActiveObject}
                    setScale={setScaleFactor}
                    setIsLoading={setIsLoading}
                    template={selectedTemplate}
                    productKey={productKey}
                    sideContainer=".cardFront"
                  />
                </div>
                <div className="cardBack" style={{ display: isFlipped ? 'block' : 'none' }}>
                  <BitmapEditor
                    ref={backEditorRef}
                    setActiveObject={setActiveObject}
                    setScale={setScaleFactor}
                    setIsLoading={setIsLoading}
                    template={selectedTemplate}
                    productKey={productKey}
                    sideContainer=".cardBack"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {(activeObject instanceof LimitedIText || activeObject instanceof LimitedTextbox) && (productType === 'wall' || productType === 'canvas') ? (
        <FontMenu textObject={activeObject as ILimitedIText} />
      ) : (
        <EditorMenu
          selectedFrame={selectedFrame}
          setSelectedFrame={setSelectedFrame}
          handleTemplateChangeByName={handleTemplateChangeByName}
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          selectedFont={selectedFont}
          setSelectedFont={setSelectedFont}
          selectedTextObject={selectedTextObject}
          productType={productType}
          selectedCollage={selectedCollage}
          setSelectedCollage={setSelectedCollage}
        />
      )}

      <Modal
        showModal={showModal}
        title={t('BitmapEditor.backModal.title')}
        message={t('BitmapEditor.backModal.message')}
        confirmText={t('BitmapEditor.backModal.confirm')}
        cancelText={t('BitmapEditor.backModal.cancel')}
        onConfirm={confirmBack}
        onCancel={cancelBack}
        showLoadingOnSave={false}
      />

      <Modal
        showModal={showValidationModal}
        title={t('BitmapEditor.validationError.modalTitle')}
        message={validationModalText}
        confirmText={t('BitmapEditor.validationError.modalConfirmBtnText')}
        cancelText={t('BitmapEditor.backModal.cancel')}
        onConfirm={() => {
          setShowValidationModal(false);
          handleSave();
        }}
        onCancel={() => {
          setShowValidationModal(false);
        }}
        showLoadingOnSave={false}
      />

      <Modal
        showModal={saveModalShow}
        title={
          (productType === 'wall' || productType === 'canvas') ? 
          t('BitmapEditor.saveModal.title') :
          t('BitmapEditor.saveModal.titleCard')
        }
        message={
          (productType === 'wall' || productType === 'canvas') ? 
          t('BitmapEditor.saveModal.message') :
          t('BitmapEditor.saveModal.messageCard')
        }
        confirmText={t('BitmapEditor.saveModal.confirm')}
        cancelText={t('BitmapEditor.saveModal.cancel')}
        onConfirm={confirmCheckout}
        onCancel={cancelCheckout}
        showLoadingOnSave={true}
      />

      {showUpgradeModal && (
        <FrameUpgradeModal
          productType={productType}
          productKey={productKey}
          setProductKey={setProductKey}
          setSaveModalShow={setSaveModalShow}
          setShowUpgradeModal={setShowUpgradeModal}
        />
      )}
    </main>
  );
};

export default PhotoEditor;
